@keyframes ping {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  10% {
    transform: scale(1.3);
    opacity: 0.8;
  }
  20% {
    transform: scale(1);
    opacity: 1;
  }
  30% {
    transform: scale(1.3);
    opacity: 0.8;
  }
  40% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.3);
    opacity: 0.8;
  }
  60% {
    transform: scale(1);
    opacity: 1;
  }
  70% {
    transform: scale(1.3);
    opacity: 0.8;
  }
  80% {
    transform: scale(1);
    opacity: 1;
  }
  90% {
    transform: scale(1.3);
    opacity: 0.8;
    border-width: 2px;
  }
  100% {
    transform: scale(1);
    opacity: 1;
    border-width: 2px;
  }
}
.animate-focus {
  /* this animation will last for 30 seconds */
  animation: ping 9s cubic-bezier(0, 0, 0.2, 1) 1;
}

@keyframes sparkle {
  0% {
    filter: brightness(1.34);
  }
  1% {
    filter: brightness(1.06);
  }
  2% {
    filter: brightness(0.11);
  }
  3% {
    filter: brightness(1.87);
  }
  4% {
    filter: brightness(0.86);
  }
  5% {
    filter: brightness(0.54);
  }
  6% {
    filter: brightness(0.61);
  }
  7% {
    filter: brightness(1.49);
  }
  8% {
    filter: brightness(0.63);
  }
  9% {
    filter: brightness(0.44);
  }
  10% {
    filter: brightness(0.35);
  }
  11% {
    filter: brightness(2);
  }
  12% {
    filter: brightness(0.87);
  }
  13% {
    filter: brightness(1.03);
  }
  14% {
    filter: brightness(1.63);
  }
  15% {
    filter: brightness(0.21);
  }
  16% {
    filter: brightness(0.61);
  }
  17% {
    filter: brightness(1.87);
  }
  18% {
    filter: brightness(1.11);
  }
  19% {
    filter: brightness(1.79);
  }
  20% {
    filter: brightness(1.84);
  }
  21% {
    filter: brightness(0.7);
  }
  22% {
    filter: brightness(0.47);
  }
  23% {
    filter: brightness(1.19);
  }
  24% {
    filter: brightness(1.48);
  }
  25% {
    filter: brightness(1.23);
  }
  26% {
    filter: brightness(1.85);
  }
  27% {
    filter: brightness(0.31);
  }
  28% {
    filter: brightness(1.5);
  }
  29% {
    filter: brightness(0.07);
  }
  30% {
    filter: brightness(0.67);
  }
  31% {
    filter: brightness(1.95);
  }
  32% {
    filter: brightness(1.99);
  }
  33% {
    filter: brightness(1.54);
  }
  34% {
    filter: brightness(1.22);
  }
  35% {
    filter: brightness(1.27);
  }
  36% {
    filter: brightness(1.81);
  }
  37% {
    filter: brightness(0.39);
  }
  38% {
    filter: brightness(1.33);
  }
  39% {
    filter: brightness(1.09);
  }
  40% {
    filter: brightness(0.99);
  }
  41% {
    filter: brightness(1.01);
  }
  42% {
    filter: brightness(1.3);
  }
  43% {
    filter: brightness(1.25);
  }
  44% {
    filter: brightness(0.91);
  }
  45% {
    filter: brightness(1.91);
  }
  46% {
    filter: brightness(0.12);
  }
  47% {
    filter: brightness(0.56);
  }
  48% {
    filter: brightness(1.51);
  }
  49% {
    filter: brightness(0.83);
  }
  50% {
    filter: brightness(0.79);
  }
  51% {
    filter: brightness(0.32);
  }
  52% {
    filter: brightness(1.52);
  }
  53% {
    filter: brightness(1.87);
  }
  54% {
    filter: brightness(1.14);
  }
  55% {
    filter: brightness(0.58);
  }
  56% {
    filter: brightness(1.77);
  }
  57% {
    filter: brightness(1.04);
  }
  58% {
    filter: brightness(1);
  }
  59% {
    filter: brightness(1.58);
  }
  60% {
    filter: brightness(1.39);
  }
  61% {
    filter: brightness(0.42);
  }
  62% {
    filter: brightness(0.34);
  }
  63% {
    filter: brightness(0.98);
  }
  64% {
    filter: brightness(1.68);
  }
  65% {
    filter: brightness(1.12);
  }
  66% {
    filter: brightness(0.66);
  }
  67% {
    filter: brightness(0.29);
  }
  68% {
    filter: brightness(1.43);
  }
  69% {
    filter: brightness(1.01);
  }
  70% {
    filter: brightness(1.32);
  }
  71% {
    filter: brightness(0.52);
  }
  72% {
    filter: brightness(1.02);
  }
  73% {
    filter: brightness(1.38);
  }
  74% {
    filter: brightness(0.31);
  }
  75% {
    filter: brightness(1.33);
  }
  76% {
    filter: brightness(1.07);
  }
  77% {
    filter: brightness(1.83);
  }
  78% {
    filter: brightness(0.57);
  }
  79% {
    filter: brightness(1.79);
  }
  80% {
    filter: brightness(1.39);
  }
  81% {
    filter: brightness(0.72);
  }
  82% {
    filter: brightness(0.5);
  }
  83% {
    filter: brightness(0.2);
  }
  84% {
    filter: brightness(1.82);
  }
  85% {
    filter: brightness(0.29);
  }
  86% {
    filter: brightness(0.76);
  }
  87% {
    filter: brightness(1.33);
  }
  88% {
    filter: brightness(0.57);
  }
  89% {
    filter: brightness(0.16);
  }
  90% {
    filter: brightness(1.93);
  }
  91% {
    filter: brightness(0.51);
  }
  92% {
    filter: brightness(0.32);
  }
  93% {
    filter: brightness(0.25);
  }
  94% {
    filter: brightness(1.56);
  }
  95% {
    filter: brightness(0.36);
  }
  96% {
    filter: brightness(1.92);
  }
  97% {
    filter: brightness(1.91);
  }
  98% {
    filter: brightness(0.93);
  }
  99% {
    filter: brightness(1.11);
  }
  100% {
    filter: brightness(1.17);
  }
}
.animate-sparkle:hover {
  animation: sparkle 10s cubic-bezier(0, 0, 0.2, 1) infinite;
}

@keyframes placeholder {
  0% {
    background-color: #e0e0e0;
  }
  50% {
    background-color: #f5f5f5;
  }
  100% {
    background-color: #e0e0e0;
  }
}
.img-placeholder {
  animation: placeholder ease-in-out 2s infinite;
}/*# sourceMappingURL=animations.css.map */