@keyframes ping {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  10% {
    transform: scale(1.3);
    opacity: 0.8;
  }

  20% {
    transform: scale(1);
    opacity: 1;
  }

  30% {
    transform: scale(1.3);
    opacity: 0.8;
  }

  40% {
    transform: scale(1);
    opacity: 1;
  }

  50% {
    transform: scale(1.3);
    opacity: 0.8;
  }

  60% {
    transform: scale(1);
    opacity: 1;
  }

  70% {
    transform: scale(1.3);
    opacity: 0.8;
  }

  80% {
    transform: scale(1);
    opacity: 1;
  }

  90% {
    transform: scale(1.3);
    opacity: 0.8;
    border-width: 2px;
  }

  100% {
    transform: scale(1);
    opacity: 1;
    border-width: 2px;
  }
}

.animate-focus {
  /* this animation will last for 9 seconds */
  animation: ping 6s cubic-bezier(0, 0, 0.2, 1) 3;
}

// --------------------------------------------------

$steps: 100; // Number of steps, including 0% and 100%
$step-size: 1%; // Increment size for each step

@function random-float($min, $max) {
  @return random(($max - $min) * 100) / 100 + $min;
}

@keyframes sparkle {
  @for $i from 0 through $steps {
    $percentage: $i * $step-size;
    #{$percentage} {
      filter: brightness(#{random-float(0, 2)});
    }
  }
}

.animate-sparkle:hover {
  animation: sparkle 10s cubic-bezier(0, 0, 0.2, 1) infinite;
}

// --------------------------------------------------

@keyframes placeholder {
  0% {
    background-color: #e0e0e0;
  }
  50% {
    background-color: #f5f5f5;
  }
  100% {
    background-color: #e0e0e0;
  }
}

.img-placeholder {
  animation: placeholder ease-in-out 2s infinite;
}
