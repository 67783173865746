@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Source+Sans+3:wght@200;300;400;500;600;700;800;900&display=swap");

* {
  font-family: "Source Sans 3", sans-serif;
}

/* hide scrollbar but allow scrolling */
* {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
}

*::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

body {
  background-color: #002256;
}

.line {
  position: absolute;
  border-top: 1px solid transparent;
  border-style: dashed;
  width: 0;
  transform-origin: 0 0;
}

.dashed-line {
  height: 1px;
  background-image: linear-gradient(90deg, transparent, transparent 100%),
    linear-gradient(90deg, white, transparent 70%);
  background-size: 20px 3px, 100% 3px;
  border: none;
}

.background-animate {
  background-size: 400%;

  -webkit-animation: AnimationName 3s ease infinite;
  -moz-animation: AnimationName 3s ease infinite;
  animation: AnimationName 3s ease infinite;
}

@keyframes ping-animate {
  0% {
    scale: 1;
    opacity: 1;
  }
  20% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  50% {
    scale: 1.15;
  }
  60% {
    opacity: 0;
  }
  80% {
    opacity: 1;
  }
  100% {
    scale: 1;
    /* opacity: 0; */
  }
}

.ping-animation {
  -webkit-animation: ping-animate 2s ease infinite;
  -moz-animation: ping-animate 2s ease infinite;
  animation: ping-animate 2s ease infinite;
}

@keyframes AnimationName {
  0%,
  100% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
}

.vh-100 {
  @apply h-screen z-10 flex items-center;
}

.bg-gradient {
  @apply bg-gradient-to-b from-[#002256]  to-[#170012];
}

.selected {
  @apply bg-blue-50 text-base text-blue-700 rounded-l-md px-5 py-2 font-bold;
}

.hover:hover {
  @apply bg-white/20 px-2 cursor-pointer rounded-l-md;
}

:root {
  --star-color-A: red;
  --star-color-B: transparent;
}

.circle {
  position: absolute;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  /* set background image as avg */
  background-size: contain;
  background-image: url("../assets/icons/stars.svg");
}

.blink_1 {
  animation-name: blink10;
  transform: rotate(20deg);
}

.blink_2 {
  animation-name: blink20;
  transform: rotate(60deg);
}

.blink_3 {
  animation-name: blink30;
  transform: rotate(50deg);
}

.blink_4 {
  animation-name: blink40;
  transform: rotate(10deg);
}

.blink_5 {
  animation-name: blink50;
  transform: rotate(70deg);
}

@keyframes blink10 {
  0%,
  100% {
    opacity: 1;
  }
  20% {
    opacity: 0;
  }
}

@keyframes blink20 {
  0%,
  100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
}

@keyframes blink50 {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

@keyframes blink30 {
  0%,
  100% {
    opacity: 1;
  }
  60% {
    opacity: 0;
  }
}

@keyframes blink40 {
  0%,
  100% {
    opacity: 0;
  }
  80% {
    opacity: 1;
  }
}
